import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function BlogAMD() {
	
	return (
		<Article
			title='My experience with curing Macular Degeneration'
			takeaway_title='Measures to cure Macular Degeneration'
			page_id='Blog - AMD'
			read_time='6 mins'
			author='Brij Bhushan'
			image1='/amd.jpg'
			image1_description='Macular Degeneration causes loss of central vision'
			image2='/OCT_scan.png'
			image2_description='Fluid accumulation under retina, which was observed in May 2019 (immediately before the intravitreal injection was administered), was not noticed again once these diet changes were made'
			toggle_article='Solution'
			toggle_url='/article/soln_amd'
			related_article='Solution - AMD'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
			<div className='article_container'>
					<p className='article_para'>
						My father was around 70 years old when he was diagnosed with Macular Degeneration (also referred as AMD). However, it was a bit strange that the doctor just said that he has some fluid accumulation underneath his retina and the treatment is an intravitreal injection. It was much later that I came to know the name of the disease. I wonder why the doctor never named the disease.
					</p>
					<p className='article_para'>
						Anyway, here is how it happened. After my father complained about some problem with his right eye, I took him to an ophthalmologist for the checkup. The doctor examined his retina & recommended an OCT test. After examining the OCT report he said some fluid accumulation is building up underneath the retina and prescribed Avastin intravitreal injection. We took an appointment at the Operation Theatre for the next available date & after administering the injection, the doctor asked us to come for the follow up on the next day. The next day, the doctor examined the eye for any possible complication. After he found everything was ok, he asked us to revisit him after 3 weeks. After 3 weeks, the doctor said we will have to repeat it to stabilize the results. This way, my father had 3 injections with a gap of 1 month between each of them. The doctor told us that according to the trials it is not known yet whether it is safe to administer more than 3 consecutive injections.
					</p>
					<p className='article_para'>
						After this, we kept visiting the doctor as & when he asked to follow up. Slowly the frequency reduced to once in 6 months. Roughly, 8 years later, my father again reported that he saw a dark circle in the middle of his vision. We reached out to the same hospital for the appointment. We were told that the doctor has moved to a different hospital and we were given an appointment with their new ophthalmologist with speciality in retina. When we consulted the doctor we were told that the same treatment has to be repeated and there is hardly any alternative. This time the treatment didn’t stop after 3 injections and we were told that the trials have shown that one can have as many consecutive injections as required.
					</p>
					<p className='article_para'>
						Then we consulted the previous doctor for his opinion & he also recommended the same. This way, my father received 3 more injections and we didn’t see any end of it. That’s when we got a reference of an eye-speciality hospital in the town and we decided to switch the hospital for the treatment. However, hospitals changed, doctors changed and treatment kept growing more expensive but my father kept on receiving injections in his eye for the next 2.5 years. Gradually, doctors moved from Avastin to Accentrix, then Lucentis and finally to Eylea. Along with the injections, my father was asked to take a multivitamin tablet and a few eyedrops for the rest of his life.
					</p>
					<p className='article_para'>
						This was the time when I started research on the disease. First thing that I figured out was the name of the disease. I noticed that my father’s discharge summary mentions ARMD as the diagnosis and it stands for Age-related Macular Degeneration. Then I went through a 1-hr long online lecture from a retina specialist, which was a recording of a conference. Then I learnt about the terms Macular Edema, Macular Degeneration and  Diabetic Retinopathy. I also learnt about the difference between them. As I continued research on Macular Degeneration, I learnt about the importance of fruits & vegetables in the diet and importance of antioxidants in the treatment of Macular Degeneration. I also learnt how Insulin Resistance can worsen the condition.
					</p>
					<p className='article_para'>
						Based on my research, I made a few changes in my father’s diet. I added fresh fruits in his diet and added Saffron tea in his morning routine. Here is the complete list of changes required in diet & lifestyle:
						<ol className='article_list'>
							<li>Add fruits & vegetables in the diet</li>
    						<li>Drink saffron tea every morning (empty stomach)</li>
    						<li>No sugar or sugar-based products</li>
    						<li>Daily 14 hrs fasting</li>
    						<li>Quit smoking, if you do</li>
    						<li>Stop using refined seed oils in cooking</li>
    						<li>Regular exercise</li>
    					</ol>
					</p>
					<p className='article_para'>
						(For explanation of each principle and details on their rationale & references, one should refer to my other article on the AMD solution <Link to='/article/soln_amd'>here</Link>)
					</p>
					<p className='article_para'>
						Now, I would like to share with you what was my thought process when I started Saffron in my father’s diet. Once I was in the leading organic store of the country and I noticed Saffron displayed on one of the counters. When I inquired about the price of the 5 gram bottle, it was Rs 2,500. First I thought that I should research further on the topic to find a cheaper alternative. Then I thought, if adding this in the diet can replace the need of the intravitreal injection (which costs around Rs 35,000), it is still value for its money. 
					</p>
					<p className='article_para'>
						Once we made these changes, results were visible within 3 months. In all subsequent follow ups with the doctor, the OCT test came normal. It has been 2 yrs now & there has been no need for the intravitreal injections during these 2 yrs. Even though the cost of 5 grams of Saffron has now escalated to Rs 3,500, still we have absorbed these principles in his diet & lifestyle permanently, because we can’t take risk of the problem reemerging again.
					</p>
			</div>
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: 'Add fruits & vegetables in the diet',
	},
	{
		takeaway: 'Drink saffron tea every morning (empty stomach)',
	},
	{
		takeaway: 'No sugar or sugar-based products',
	},
	{
		takeaway: 'Daily 14 hrs fasting',
	},
	{
		takeaway: 'Quit smoking, if you do',
	},
	{
		takeaway: 'Stop using refined seed oils in cooking',
	},
	{
		takeaway: 'Regular exercise',
	},
];

const RelatedArticles = [
	{
		title: 'Solution - AMD',
		path: '/article/soln_amd',
	},
];